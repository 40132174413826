import { render, staticRenderFns } from "./NetdocRoom.vue?vue&type=template&id=232264cc&scoped=true"
import script from "./NetdocRoom.vue?vue&type=script&lang=js"
export * from "./NetdocRoom.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232264cc",
  null
  
)

export default component.exports