import { render, staticRenderFns } from "./PPortList.vue?vue&type=template&id=3cc2bb14&scoped=true"
import script from "./PPortList.vue?vue&type=script&lang=js"
export * from "./PPortList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc2bb14",
  null
  
)

export default component.exports