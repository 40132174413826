<template>
  <div>
    <b-card no-body class="shadow">
      <PaginatorTable :fields="site_list_fields" :busy="p_ports === null"
               :items="p_ports" :sort-by="sort_by || default_sort_by" :hide-filter="p_ports.length < 10"
               :hide-top-pagination="p_ports.length < 25" :show_default_no_items_warning="false">
        <template v-slot:cell(name)="data">
          <b-link class="nowrap" :to="'/netdoc/modules/'+data.item.module_gfk+'/p_port/'+data.item.gpk">
            <b>{{ data.item.mdl_fq_name }}: {{ data.value }}</b>
          </b-link>
        </template>
        <template v-slot:cell(is_defective)="data">
          <CheckMark :state="data.item.is_defective"/>
        </template>
        <template v-slot:cell(is_connected)="data">
          <CheckMark :state="data.item.is_connected"/>
        </template>
      </PaginatorTable>
      <div v-if="p_ports && p_ports.length === 0" class="font-italic text-center mb-3">
        {{ $t('components.bcd_list.no_p_ports_available') }}
      </div>
    </b-card>
  </div>
</template>

<script>
import CheckMark from '@/components/CheckMark.vue'
import PaginatorTable from '@/components/PaginatorTable.vue'

export default {
  name: 'PPortList',
  components: { PaginatorTable, CheckMark },
  watch: {
    filter: {
      immediate: true,
      async handler() {
        if (this.filter_callback !== null) {
          const result = await this.filter_callback(this.filter)
          if (result !== null) {
            this.filter = result
          }
        }
      }
    },
  },
  data() {
    return {
      filter: '',
      current_page: 1,
      per_page: 100,
      sort_by: null,
    }
  },
  computed: {
    site_list_fields() {
      return [
        {
          label: this.$t('system.name'),
          key: 'name',
          sortable: true
        },
        {
          label: this.$t('system.description'),
          key: 'description',
          sortable: true
        },
        {
          label: this.$t('views.nd.modules.port_protocol'),
          key: 'protocol',
          sortable: true
        },
        {
          label: this.$t('views.nd.modules.port_speed'),
          key: 'speed',
          sortable: true
        },
        {
          label: this.$t('views.nd.p_port.is_connected'),
          key: 'is_connected',
          sortable: true
        },
        {
          label: this.$t('views.nd.bcd.defective'),
          key: 'is_defective',
          sortable: true
        },
      ]
    }
  },
  props: {
    default_sort_by: {
      required: false,
      default() {
        // FIXME: modules have a canonical order...
        return 'mdl_fq_name'
      }
    },
    p_ports: {
      required: true,
      default() {
        return null
      }
    },
    filter_callback: {
      required: false,
      default() {
        return null
      }
    },
  }
}
</script>

<style scoped>
</style>
