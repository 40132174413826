<template>
  <div id="Site">
    <h1 v-if="room">{{ $tc('views.nd.netdoc.room', 1) }} {{ room.number+': '+room.name }}</h1>
    <LoadingPlaceholder :data="[bldg, room, modules]">
      <div style="margin-top: 15px">
        <b-row class="mt-n4" v-if="bldg && room" >
          <b-col cols="3">
            <ComponentLocation :bldg_name="bldg.name" :room_name="room.name"/>
          </b-col>
          <b-col cols="3">
            <div v-if="this.bldg">
              <div style="float: left;">
                <NETVSIcon icon="geo_address" class="pr-2"></NETVSIcon>
              </div>
              <div style="float: left;">
                {{ bldg.name }}
                <br>
                {{ bldg.street }}
                <br>
                {{ bldg.postal_code }}
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <KITMap v-if="can_show_map" :center="bldg.geo_location" :zoom="17" style="height: 20vh; width: 100%;">
              <template v-slot:map_content>
                <l-marker :lat-lng="latLng(bldg.geo_location.lat, bldg.geo_location.lng)"
                          :icon="icon"></l-marker>
              </template>
            </KITMap>
          </b-col>
        </b-row>
      </div>
      <PPortList :p_ports="p_ports" />
    </LoadingPlaceholder>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/components/Loading.vue'
import netdocService from '@/api-services/netdoc.service'
import PPortList from '@/components/nd/PPortList.vue'
import {EventBus} from '@/eventbus'
import ComponentLocation from '@/components/nd/ComponentLocation.vue'
import NETVSIcon from '@/icons/NETVSIcon.vue'
import KITMap from '@/components/KITMap.vue'
import {icon, latLng} from 'leaflet'
import {LMarker} from 'vue2-leaflet'

export default {
  name: 'NetdocBldg',
  methods: { latLng },
  components: { KITMap, NETVSIcon, ComponentLocation, PPortList, LoadingPlaceholder, LMarker },
  data() {
    return {
      site: null,
      bldg: null,
      room: null,
      modules: null,
      p_ports: null,
      icon: icon({iconUrl: '/location.png', iconSize: [32, 42], iconAnchor: [16, 42]})
    }
  },
  async created() {
    const response = await netdocService.getRoomInfo(this.$store.state, this.$route.params.site, this.$route.params.bldg, this.$route.params.room_gpk)
    this.site = response.data.site_list[0]
    this.bldg = response.data.bldg_list[0]
    this.room = response.data.room_list[0]
    this.modules = response.data.module_list
    this.p_ports = response.data.p_port_list

    const n = this.room.number // Don't lazy eval, this can cause a poo-storm in the next line on navigation to the same view iwth a different p_port
    EventBus.$emit('overwrite_breadcrumbs', {room_gpk: () => n})
  },
  computed: {
    can_show_map: function () {
      return this.bldg && this.bldg.geo_location && this.bldg.geo_location.lng && this.bldg.geo_location.lat
    },
  }
}
</script>

<style scoped>

</style>
